var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('FilterPicker',{model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}}),_c('TestList',{attrs:{"filters":_vm.selectedFilters},model:{value:(_vm.selectedTests),callback:function ($$v) {_vm.selectedTests=$$v},expression:"selectedTests"}}),(_vm.testSummary || _vm.showGraph)?_c('v-card',[(_vm.testSummary)?_c('v-btn',{on:{"click":_vm.removeSummary}},[_vm._v("Close Summary")]):_vm._e(),(_vm.showGraph)?_c('v-btn',{on:{"click":_vm.hideGraph}},[_vm._v("Close Graph")]):_vm._e()],1):_vm._e(),(_vm.testSummary)?_c('v-card',{staticClass:"test-summary",domProps:{"innerHTML":_vm._s(_vm.testSummary)}}):_vm._e(),(_vm.showGraph)?_c('v-card',{staticClass:"graphs"},[_c('div',{attrs:{"id":"chartContainer"}})]):_vm._e(),_c('RightPanel',{attrs:{"showPanel":_vm.selectedTests.length},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Selected Tests")])],1),_c('v-list-item-action',[(_vm.selectedTests.length)?_c('v-btn',{on:{"click":_vm.clearSelectedTests}},[_vm._v("Clear")]):_vm._e()],1)],1)]},proxy:true}])},[_c('v-data-table',{attrs:{"items":_vm.selectedTests,"headers":_vm.selectedTestsHeaders,"group-by":"assay_name","show-group-by":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","plain":"","color":"primary"},on:{"click":function($event){return _vm.deselectTest(item.id)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])}),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.downloadDisabled},on:{"click":_vm.getNXXArchive}},[_vm._v(" Download NXX ")]),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":_vm.getHTMLSummary}},'v-btn',attrs,false),on),[_vm._v(" Show Summary ")])]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":_vm.getExcelSummary}},[_c('v-btn',[_vm._v("Download Excel")])],1),_c('v-radio-group',{model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}},[_c('v-radio',{attrs:{"label":"By Assay","value":"PER_ASSAY"}}),_c('v-radio',{attrs:{"label":"By Analytes","value":"BY_ANALYTES"}}),_c('v-radio',{attrs:{"label":"One Page","value":"ONE_PAGE"}})],1)],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Graph ")])]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":_vm.getPressure}},[_c('v-btn',[_vm._v("Pressure")])],1)],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":_vm.getOptical}},[_c('v-btn',[_vm._v("Optical")])],1)],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":_vm.getTemperature}},[_c('v-btn',[_vm._v("Temp.")])],1)],1)],1)],1),_vm._l((_vm.selectedAssays),function(assay){return _c('v-card',{key:assay.guid,staticClass:"mx-auto",attrs:{"max-width":"380"}},[_c('v-card-title',[_vm._v(" "+_vm._s(assay.name)+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.readingHeaders,"items":_vm.readingsForAssay(assay),"dense":true,"show-select":"","item-key":"name","hide-default-footer":"","items-per-page":-1},model:{value:(_vm.selectedReadings[assay.name]),callback:function ($$v) {_vm.$set(_vm.selectedReadings, assay.name, $$v)},expression:"selectedReadings[assay.name]"}}),_c('v-data-table',{attrs:{"headers":_vm.analyteHeadersForAssay(assay),"items":assay.analytes,"dense":true,"show-select":"","item-key":"name","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.is_pcr",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.is_pcr),callback:function ($$v) {_vm.$set(item, "is_pcr", $$v)},expression:"item.is_pcr"}})]}},(assay.has_melt_analytes)?{key:"item.is_melt_target",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.is_melt_target),callback:function ($$v) {_vm.$set(item, "is_melt_target", $$v)},expression:"item.is_melt_target"}})]}}:null,{key:"item.is_control",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.is_control),callback:function ($$v) {_vm.$set(item, "is_control", $$v)},expression:"item.is_control"}})]}},{key:"item.is_probe",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.is_probe),callback:function ($$v) {_vm.$set(item, "is_probe", $$v)},expression:"item.is_probe"}})]}}],null,true),model:{value:(_vm.selectedAnalytes[assay.name]),callback:function ($$v) {_vm.$set(_vm.selectedAnalytes, assay.name, $$v)},expression:"selectedAnalytes[assay.name]"}})],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }