var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tests,"item-key":"test_hash","dense":true,"server-items-length":_vm.testCount,"show-select":"","page":_vm.options.page,"footer-props":{
    showFirstLastPage: true,
    showCurrentPage: true,
    itemsPerPageOptions: [
      5, 10, 25, 50, 100
    ],
  }},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.assay_name",fn:function(ref){
  var item = ref.item;
return [(item.assay_id != null)?_c('router-link',{attrs:{"title":("GUID:" + (item.assay_guid) + "\nSHA256:" + (item.assay_hash)),"to":{ name: 'main-assay-view', params: { id: item.assay_id }}}},[_vm._v(_vm._s(item.assay_name))]):_c('span',[_vm._v(_vm._s(item.assay_name))])]}},{key:"item.sample_id",fn:function(ref){
  var item = ref.item;
return [(item.test_id != null)?_c('router-link',{attrs:{"title":("GUID:" + (item.test_guid) + "\nSHA256:" + (item.test_hash)),"to":{ name: 'main-test-view', params: { id: item.id }}}},[_vm._v(_vm._s(item.sample_id))]):_c('span',[_vm._v(_vm._s(item.sample_id))])]}},{key:"item.start_time",fn:function(ref){
  var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatTime(value, false))+" ")]}},{key:"item.created_at",fn:function(ref){
  var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatTime(value, true, "America/Los_Angeles"))+" ")]}},{key:"item.result",fn:function(ref){
  var value = ref.value;
return [(value.indexOf('|') == -1)?_c('span',[_vm._v(_vm._s(value))]):_vm._e(),(value.indexOf('|') >= 0)?_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-chip',_vm._g({},on),[_vm._v(_vm._s(_vm.splitResult(value)[0])+"...")])]}}],null,true)},[_c('v-list',_vm._l((_vm.splitResult(value)),function(item){return _c('v-list-item',{key:item},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1):_vm._e()]}},{key:"item.parse",fn:function(ref){
  var item = ref.item;
return [(item.assay_id && item.test_id)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_vm._e(),(!item.assay_id || !item.test_id)?_c('v-icon',[_vm._v("mdi-refresh")]):_vm._e()]}},{key:"item.assay_file",fn:function(ref){
  var item = ref.item;
return [(item.assay_id)?_c('v-icon',[_vm._v("mdi-download")]):_vm._e()]}},{key:"item.test_file",fn:function(ref){
  var item = ref.item;
return [(item.test_id)?_c('v-icon',[_vm._v("mdi-download")]):_vm._e()]}}]),model:{value:(_vm.selectedTests),callback:function ($$v) {_vm.selectedTests=$$v},expression:"selectedTests"}})}
var staticRenderFns = []

export { render, staticRenderFns }