var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_vm._l((_vm.selectedFilters),function(filter,idx){return _c('v-row',{key:filter.id},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"items":_vm.availableFilters,"item-text":"name","item-value":"value","label":"Field","single-line":"","dense":""},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.filterTargetChange.apply(void 0, [ idx ].concat( argsArray ))}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-btn',{attrs:{"small":"","icon":"","plain":"","color":"primary"},on:{"click":function($event){return _vm.removeFilter(idx)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]},proxy:true}],null,true),model:{value:(_vm.selectedFilters[idx].value),callback:function ($$v) {_vm.$set(_vm.selectedFilters[idx], "value", $$v)},expression:"selectedFilters[idx].value"}})],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.filterOperators[filter.value])?_c('v-select',{attrs:{"items":_vm.filterOperators[filter.value],"item-text":"name","item-value":"name","label":"Filter","single-line":"","clearable":"","dense":""},on:{"change":function($event){_vm.selectedFilters[idx].filterValue = undefined}},model:{value:(_vm.selectedFilters[idx].selectedOperator),callback:function ($$v) {_vm.$set(_vm.selectedFilters[idx], "selectedOperator", $$v)},expression:"selectedFilters[idx].selectedOperator"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"5"}},[(_vm.filterOperators[filter.value] && _vm.filterTypes[filter.value] == 'text')?_c('v-combobox',{attrs:{"dense":"","items":_vm.selectedFilters[idx].items,"return-object":false,"item-value":"value","clearable":""},on:{"update:search-input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.filterValueChange.apply(void 0, [ idx ].concat( argsArray ))}},model:{value:(_vm.selectedFilters[idx].filterValue),callback:function ($$v) {_vm.$set(_vm.selectedFilters[idx], "filterValue", $$v)},expression:"selectedFilters[idx].filterValue"}}):_vm._e(),(_vm.selectedFilters[idx].selectedOperator && _vm.filterOperators[filter.value] && _vm.filterTypes[filter.value] == 'date')?_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select Date","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.selectedFilters[idx].filterValue),callback:function ($$v) {_vm.$set(_vm.selectedFilters[idx], "filterValue", $$v)},expression:"selectedFilters[idx].filterValue"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.selectedFilters[idx].showMenu),callback:function ($$v) {_vm.$set(_vm.selectedFilters[idx], "showMenu", $$v)},expression:"selectedFilters[idx].showMenu"}},[_c('v-date-picker',{attrs:{"range":_vm.selectedFilters[idx].selectedOperator == 'between'},on:{"change":function($event){_vm.selectedFilters[idx].showMenu = false; _vm.updatefilters();}},model:{value:(_vm.selectedFilters[idx].filterValue),callback:function ($$v) {_vm.$set(_vm.selectedFilters[idx], "filterValue", $$v)},expression:"selectedFilters[idx].filterValue"}})],1):_vm._e()],1)],1)}),_c('v-btn',{attrs:{"rounded":"","color":"primary","dark":""},on:{"click":_vm.addFilter}},[_vm._v(" Add Filter ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }