



















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class RightPanel extends Vue {
  @Prop()
  public readonly showPanel!: boolean;
}
