













































































import { Component, Vue, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { readHasAdminAccess } from '@/store/main/getters';
import { ITestResult, IAssay, IAnalyte, ITrace, IAutocompleteItem, IFilter, ITableOptions } from '@/interfaces';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

@Component
export default class TestList extends Vue {
  @ModelSync('value', 'change', { type: Array })
  public readonly selectedTests!: ITestResult[];

  public headers: object[] = [
        { text: 'Sample', value: 'sample_id', cellClass: 'col-sample-id' },
        { text: 'System Name', value: 'system_name' },
        { text: 'User', value: 'gx_username', cellClass: 'col-name' },
        { text: 'Status', value: 'status', cellClass: 'col-status' },
        { text: 'Result', value: 'result', cellClass: 'col-result' },
        { text: 'Start', value: 'start_time', cellClass: 'col-date' },
        { text: 'Assay Name', value: 'assay_name', cellClass: 'col-name' },
        { text: 'Assay Version', value: 'assay_version' },
        { text: 'Software Version', value: 'system_sw', cellClass: 'col-system' },
        { text: 'Upload Date', value: 'created_at', cellClass: 'col-date' },
        { text: 'Instrument', value: 'instrument_serial' },
        { text: 'Module', value: 'module_serial' },
        { text: 'Cart', value: 'cart_serial' },
        { text: 'Reagent Lot', value: 'reagent_lot' },
      ];

  @Prop() public readonly filters!: IFilter[];

  public tests: ITestResult[] = [];
  public testCount: number = 0;

  public options: ITableOptions = {
    page: 1,
    perPage: 10,
    sortBy: 'created_at',
    sortDesc: true,
  };

  @Watch('filters')
  public async onFiltersChanged(val: IFilter[], oldVal: IFilter[]) {
    this.options.page = 1;
    this.updateTestView();
  }

  public splitResult(r: string) {
    return r.split('|').filter((v) => v.length > 0);
  }

  public async getTestCount() {
    try {
      const response = await api.getTestsCount(readToken(this.$store), this.filters || []); // TODO: un-hack this fix
      if (response) {
        this.testCount = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async getTests() {
    try {
      const response = await api.getTests(
        readToken(this.$store),
        {filters: this.filters , ...this.options},
      );
      if (response) {
          this.tests = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async updateTestView() {
    this.getTestCount();
    this.getTests();
  }

  public updateOptions(options) {
    this.options.page = options.page || 1;
    this.options.perPage = options.itemsPerPage || 10;
    this.options.sortBy = options.sortBy[0] || 'created_at';
    this.options.sortDesc = options.sortDesc[0];
    // emit an event?
    this.updateTestView();
  }

  public formatTime(dateString: string, isUTC: boolean, tz: string = 'UTC') {
    let date = parseISO(dateString);
    if (isUTC) {
      date = parseISO(`${dateString}Z`);
    }
    return format(date, 'MM/dd/yyyy h:mmaaa');
  }

  public mounted() {
    if (readHasAdminAccess(this.$store)) {
      this.headers.push(
        { text: 'Parse', value: 'parse' },
        { text: 'Assay', value: 'assay_file' },
        { text: 'Test', value: 'test_file' },
      );
    }

    this.updateTestView();
  }

  @Socket('new-test')
  public onNewTest(testInfo) {
    // console.log(JSON.parse(testInfo));
    this.updateTestView();
  }

}
