


















import TestList from '@/components/TestList.vue';
import FilterPicker from '@/components/FilterPicker.vue';
import RightPanel from '@/components/RightPanel.vue';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { IFile } from '@/interfaces';
import { format, parseISO } from 'date-fns';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

@Component
export default class Tests extends Vue {


  public headers: object[] = [
        { text: 'Filename', value: 'filename', cellClass: 'col-sample-id' },
        // { text: 'Patient', value: 'patient_id', cellClass: 'col-sample-id' },
        { text: 'Type', value: 'file_type', cellClass: 'col-result' },
        // { text: 'Specimen', value: 'specimen_type'},
        { text: 'Uploaded', value: 'upload_date' },
      ];

  public files: IFile[] = [];

  public tableOptions: object = {
    itemsPerPage: 25,
    sortBy: ['created_at'],
    sortDesc: [true],
  };


  public async mounted() {
    // dispatchGetTests(this.$store, this.selectedFilters);
    const result = await api.getArchiveFiles(readToken(this.$store));
    if (result) {
      this.files = result.data;
    }
  }

}
