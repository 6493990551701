
































































































import { Component, Vue, Watch, Model } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readTests, readTestsCount, readTestSummary, readTraces } from '@/store/tests/getters';
import { readToken } from '@/store/main/getters';
import {
  dispatchGetTests, dispatchGetTestsCount, dispatchGetTestSummary,
  dispatchGetTraces,
  } from '@/store/tests/actions';
import { commitSetTest, commitSetTestsOptions,
         commitSetTestsPage, commitSetTestsPerPage,
         commitSetTestsSortBy, commitSetTestsSortDesc,
         commitSetTestSummary,
} from '@/store/tests/mutations';
import { ITestResult, IAssay, IAnalyte, ITrace, IAutocompleteItem, IFilter } from '@/interfaces';
import { format, parseISO } from 'date-fns';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

// instrument, module, cart, gx_username, status, result, start_date, reagent_lot, assay, upload_date

@Component
export default class FilterPicker extends Vue {

  @Model('change', { type: Array })
  public readonly value!: IFilter[];

  public valueIndex: number = 0;

  public availableFilters: object[] = [
        { name: '', value: 'none'},
        { name: 'Sample ID', value: 'sample_id'},
        { name: 'Error Code', value: 'error_code'},
        { name: 'System Name', value: 'system_name'},
        { name: 'Instrument Serial #', value: 'instrument_serial'},
        { name: 'Module Serial #', value: 'module_serial'},
        { name: 'Cartridge Serial #', value: 'cart_serial'},
        { name: 'Username', value: 'gx_username'},
        { name: 'Status', value: 'status'},
        { name: 'Result', value: 'result'},
        { name: 'Start Time', value: 'start_time'},
        { name: 'Reagent Lot #', value: 'reagent_lot'},
        { name: 'Assay', value: 'assay_name'},
        { name: 'Upload Time', value: 'created_at'},

      ];

  public filterOperators: object = {
    sample_id: [
      { name: 'is', op: '='},
      { name: 'contains', op: '%'},
    ],
    error_code: [
      { name: 'is', op: '='},
    ],
    system_name: [
      { name: 'is', op: '='},
      { name: 'contains', op: '%'},
    ],
    instrument_serial: [{ name: 'is', op: '='}],
    module_serial: [{ name: 'is', op: '='}],
    cart_serial: [{ name: 'is', op: '='}],
    gx_username: [
      { name: 'is', op: '='},
      { name: 'contains', op: '%'},
    ],
    status: [{ name: 'is', op: '='}],
    result: [
      { name: 'is', op: '='},
      { name: 'contains', op: '%'}],
    start_time: [
      { name: 'on', op: '='},
      { name: 'after', op: '>='},
      { name: 'before', op: '<='},
      { name: 'between', op: '><'},
    ],
    reagent_lot: [{ name: 'is', op: '='}],
    assay_name: [
      { name: 'is', op: '='},
      { name: 'contains', op: '%'},
    ],
    created_at: [
      { name: 'on', op: '='},
      { name: 'after', op: '>='},
      { name: 'before', op: '<='},
      { name: 'between', op: '><'},
    ],
  };

  public filterTypes: object = {
    sample_id: 'text',
    error_code: 'text',
    system_name: 'text',
    instrument_serial: 'text',
    module_serial: 'text',
    cart_serial: 'text',
    gx_username: 'text',
    status: 'text',
    result: 'text',
    start_time: 'date',
    reagent_lot: 'text',
    assay_name: 'text',
    created_at: 'date',
  };

  public selectedFilters: Array<{
    name?: string,
    value?: string,
    id: any,
    items?: IAutocompleteItem[],
    selectedOperator?: string,
    filterValue?: string,
  }> = [];

  public updatefilters() {
    const filters: IFilter[] = []; // this.selectedAnalytes.map((a) => a.name);
    for (const filter of this.selectedFilters) {
      if (filter.value && filter.selectedOperator && filter.filterValue) {
        filters.push(
          {field: filter.value, operator: filter.selectedOperator, value: filter.filterValue },
        );
      }
    }
    this.$emit('change', filters);
  }

  public async filterTargetChange(idx: number, item) {
    const items = await api.getFields(readToken(this.$store), {field: item, skip: 0, limit: 100, filter: ''});
    this.$set(this.selectedFilters[idx], 'items', items.data );
    this.$set(this.selectedFilters[idx], 'filterValue', undefined );
    this.$set(this.selectedFilters[idx], 'selectedOperator', undefined );
  }
/*
  public async endIntersect(entries, observer, isIntersecting) {
    if (isIntersecting) {
      const moreItems = await api.getFields(readToken(this.$store), {field: item, skip: 0, limit: 100, filter: ''});
      this.$set(this.selectedFilters[idx], 'items', items.data );
      this.items = [ ...this.items, ...moreItems.data]
    }
  }
*/
  public async filterValueChange(idx: number, item) {
    // console.log(item);
    this.$set(this.selectedFilters[idx], 'filterValue', item );
    const items = await api.getFields(readToken(this.$store), {
      field: this.selectedFilters[idx].value || '',
      skip: 0,
      limit: 100,
      filter: item,
    });
    this.$set(this.selectedFilters[idx], 'items', items.data );
    this.updatefilters();
  }

  public addFilter() {
    const filter = {id: uuidv4(), ...this.availableFilters[0]};
    this.selectedFilters.push(filter);
  }

  public removeFilter(idx: number) {
    this.selectedFilters.splice(idx, 1);
  }

  public mounted() {
    this.addFilter();
  }
}
